<script setup lang="ts">
import { type ComputedRef } from 'vue'
import { useAuthStore } from '~/store/auth/auth'
import { type IUserPayload } from '~/store/users/types/userTypes'
import { Routes } from '~/constants/routes/Routes'
import { useSelectedCompanyForWholeAppStore } from '~/store/selectedCompanyForWholeApp/selectedCompanyForWholeApp'

const authStore = useAuthStore()
const selectedCompanyForWholeAppStore = useSelectedCompanyForWholeAppStore()
const router = useRouter()

const getAuthUserInfo: ComputedRef<IUserPayload | null> = computed((): IUserPayload | null =>
  authStore.getUser(),
)

const menu = useState(() => false)

const performLogout = () => {
  authStore.reset()
  selectedCompanyForWholeAppStore.reset()
  router.push(Routes.Root)
}
</script>

<template>
  <div class="text-center">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      location="end"
      rounded
    >
      <template #activator="{ props }">
        <v-avatar v-bind="props" class="me-4" size="32">
          <Icon name="fluent-emoji:person" size="30" color="black" />
        </v-avatar>
      </template>

      <v-card min-width="300">
        <v-list>
          <v-list-item
            :title="getAuthUserInfo?.name || 'no logged in'"
            :subtitle="getAuthUserInfo?.email || 'no logged in'"
          >
            <template #prepend>
              <Icon name="fluent-emoji:person" size="40" />
            </template>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" variant="text" @click="performLogout">
            Logout
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<style scoped lang="sass"></style>
