<script setup lang="ts">
import { useDisplay, useTheme } from 'vuetify'
import DashboardAvatar from '~/components/dashboard/avatar/dashboard-avatar.vue'
import SelectCompanyAutocomplete from '~/components/common/select-company/select-company-autocomplete.vue'
import { useAuthStore } from '~/store/auth/auth'
import Localization from '~/constants/localization/localization'

interface ILink {
  title: string
  icon: string
  link: string
  subMenuMainTitle: string | null | undefined
  subMenu: ILink[]
  mustBeSeenOnlyBySuperAdmin: boolean
}

const authStore = useAuthStore()

const theme = useTheme()

const { mdAndUp } = useDisplay()

const links: Array<ILink> = [
  {
    title: Localization.dashboard,
    icon: 'mdi-home',
    link: '/dashboard',
    subMenuMainTitle: null,
    subMenu: [],
    mustBeSeenOnlyBySuperAdmin: false,
  },
  {
    title: Localization.customers,
    icon: 'mdi-account-group',
    link: '/customers',
    subMenuMainTitle: Localization.customerManagement,
    subMenu: [
      {
        title: Localization.customerCompanies,
        icon: 'mdi-domain',
        link: '/customers/companies',
        subMenuMainTitle: null,
        subMenu: [],
        mustBeSeenOnlyBySuperAdmin: false,
      },
    ],
    mustBeSeenOnlyBySuperAdmin: false,
  },
  {
    title: Localization.product,
    icon: 'mdi-package-variant-closed',
    link: '/products',
    subMenuMainTitle: 'Products Management',
    subMenu: [
      {
        title: 'Categories',
        icon: 'mdi-format-list-bulleted',
        link: '/products/categories',
        subMenuMainTitle: null,
        subMenu: [],
        mustBeSeenOnlyBySuperAdmin: false,
      },
      {
        title: 'Attributes',
        icon: 'mdi-layers-plus',
        link: '/products/attributes',
        subMenuMainTitle: null,
        subMenu: [],
        mustBeSeenOnlyBySuperAdmin: false,
      },
    ],
    mustBeSeenOnlyBySuperAdmin: false,
  },
  {
    title: Localization.brands,
    icon: 'mdi-circle-box',
    link: '/brands',
    subMenuMainTitle: null,
    subMenu: [],
    mustBeSeenOnlyBySuperAdmin: false,
  },
  {
    title: Localization.companies,
    icon: 'mdi-domain',
    link: '/companies',
    subMenuMainTitle: null,
    subMenu: [],
    mustBeSeenOnlyBySuperAdmin: true,
  },
  /* {
    title: Localization.users,
    icon: 'mdi-account-multiple',
    link: '/users',
    subMenuMainTitle: null,
    subMenu: [],
    mustBeSeenOnlyBySuperAdmin: false,
  }, */
  {
    title: Localization.invoices,
    icon: 'mdi-invoice',
    link: '/invoices',
    subMenuMainTitle: null,
    subMenu: [],
    mustBeSeenOnlyBySuperAdmin: false,
  },
  /* {
    title: Localization.vendors,
    icon: 'mdi-hand-coin',
    link: '/invoices',
    subMenuMainTitle: null,
    subMenu: [],
    mustBeSeenOnlyBySuperAdmin: false,
  }, */
  /* {
    title: Localization.warehouses,
    icon: 'mdi-warehouse',
    link: '/warehouses',
    subMenuMainTitle: null,
    subMenu: [],
    mustBeSeenOnlyBySuperAdmin: false,
  }, */
] as Array<ILink>

const drawer = useState<boolean>(() => true)
const drawerRail = useState<boolean>(() => false)

const userIsLoggedIn = computed(()=> authStore.isUserLoggedIn);
const router = useRouter();
const activeRoute = computed(() => router.currentRoute.value.path);

onMounted(() => {
  console.log(activeRoute.value.substring(0, activeRoute.value.lastIndexOf('/values')));
})
</script>

<template>
  <v-app v-if="userIsLoggedIn">
    <v-app-bar :flat="true">
      <template #prepend>
        <v-app-bar-nav-icon @click="drawer = !drawer" />
        <v-btn
          rounded-full
          :icon="drawerRail ? 'mdi-chevron-left' : 'mdi-chevron-right'"
          @click="drawerRail = !drawerRail"
        />
      </template>
      <v-container
        v-if="mdAndUp"
        :fluid="true"
        class="mx-auto d-flex align-center justify-center"
      >
        <!--<template v-for="(link, i) in links">
          <v-btn
              v-if="
              link.link == 'companies' ? autStore.loggedUserIsAdmin() : true
            "
              :key="link.link"
              :to="link.link"
              :class="{ 'ml-4': i > 0 }"
              variant="text"
          >
            <v-icon>{{ link.icon }}</v-icon> &nbsp;
            {{ link.title }}
          </v-btn>
        </template> -->

        <v-spacer></v-spacer>

        <v-responsive max-width="300">
          <select-company-autocomplete />
        </v-responsive>
      </v-container>
      <configuration-change-theme />
      &nbsp;&nbsp;&nbsp;&nbsp;
      <div class="d-flex justify-center align-center">
        <configuration-change-language />
      </div>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <dashboard-avatar />
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      :permanent="true"
      :absolute="false"
      :temporary="true"
      :mobile-breakpoint="'sm'"
      :rail="drawerRail"
      :floating="true"
    >
      <v-list>
        <v-list-item
          prepend-avatar="~/assets/img/stocktaking.jpeg"
          title="StockTaking"
        >
          <template #append> </template>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list :nav="true">
        <template v-for="(link, i) in links">
          <v-list-group
            v-if="link.hasOwnProperty('subMenu') && link.subMenu.length > 0"
            :key="`submenu-${i}`"
          >
            <template #activator="{ props }">
              <v-list-item
                v-bind="props"
                :prepend-icon="link.icon"
                :title="$t(link.subMenuMainTitle!.toString())"
                :active="activeRoute == link.link || link.link === activeRoute.substring(0, link.link.length) || link.subMenu.some(sub => activeRoute == sub.link || activeRoute == sub.link.substring(0, sub.link.length))"
              ></v-list-item>
            </template>

            <v-list-item
              :key="`submenu-${link.title}-${i}`"
              :link="true"
              :value="link.title"
              :title="$t(link.title)"
              :prepend-icon="link.icon"
              :to="link.link"
              :active="activeRoute == link.link"
              append-icon="mdi-chevron"
            ></v-list-item>

            <v-list-item
              v-for="(subMenuItem, j) in link.subMenu"
              :key="j"
              :value="subMenuItem.title"
              :title="$t(subMenuItem.title)"
              :prepend-icon="subMenuItem.icon"
              :to="subMenuItem.link"
              :active="activeRoute == subMenuItem.link || subMenuItem.link === activeRoute.substring(0, subMenuItem.link.length)"
            ></v-list-item>
          </v-list-group>

          <template v-if="!link.mustBeSeenOnlyBySuperAdmin">
            <v-list-item
              v-if="link.hasOwnProperty('subMenu') && link.subMenu.length == 0"
              :key="`side-${i}`"
              :prepend-icon="link.icon"
              :link="true"
              :to="link.link"
              :active="activeRoute == link.link || link.link === activeRoute.substring(0, link.link.length)"
            >
              <template #title>{{ $t(link.title) }}</template>
            </v-list-item>
          </template>
          <template v-else-if="authStore.loggedUserIsAdmin()">
            <v-list-item
              v-if="link.hasOwnProperty('subMenu') && link.subMenu.length == 0"
              :key="`side-${i}`"
              :prepend-icon="link.icon"
              :title="link.title"
              :link="true"
              :to="link.link"
              :active="activeRoute == link.link"
            />
          </template>
        </template>
      </v-list>
    </v-navigation-drawer>
    <!-- <v-app-bar :flat="true">
      <v-container
        v-if="mdAndUp"
        class="mx-auto d-flex align-center justify-center"
      >
        <v-btn
          v-for="(link, i) in links"
          :key="link.toString()"
          :class="{'ml-4': i > 0}"
          :to="link.link"
          variant="text"
        >
          <v-icon>{{link.icon}}</v-icon>
          {{ link.title }}
        </v-btn>
      </v-container>
    </v-app-bar> -->

    <v-main :class="{ 'bg-grey-lighten-3': !theme.global.current.value.dark }">
      <slot />
    </v-main>

    <!--<v-main class="bg-grey-lighten-3">
      <v-container :fluid="true">
        <v-row>
          <v-col>
            <slot />
          </v-col>
        </v-row>
      </v-container>
    </v-main> -->
  </v-app>
  <v-app v-else>

    <v-main :class="{ 'bg-grey-lighten-3': !theme.global.current.value.dark}" class="d-flex justify-center align-center">
      <v-progress-circular :indeterminate="true"/>
    </v-main>
  </v-app>
</template>
